import React from 'react';
import styled from 'styled-components';

import { Container } from '@nfs-react/components';

import { DESKTOP, TABLET } from 'helpers/styles/media';
import { Video } from 'helpers/components';

const VideoComponent = () => {
  return (
    <VideoSection>
      <Container>
        <Inner>
          <VideoWrapper>
            <Video autoPlay controls theme={{ mainColor: '#474747' }} />
          </VideoWrapper>
        </Inner>
      </Container>
    </VideoSection>
  );
};

const VideoSection = styled.div`
  padding: 30px 0;
`;

const Inner = styled.div`
  margin: 0 auto;

  @media ${TABLET} {
  }
`;

const VideoWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;

  @media ${DESKTOP} {
    max-width: 768px;
  }
`;

export default VideoComponent;
